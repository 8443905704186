import pako from "pako";

const logSize = (label, step) => (val) => {
  console.info(`[${label}: ${step}] ${val.length} chars.`);
  return val;
};

export const inflate = (compressed) =>
  Promise.resolve(compressed)
    .then(logSize("inflate", "initial"))
    .then(decodeURIComponent)
    .then(logSize("inflate", "decoded"))
    .then(atob)
    .then(logSize("inflate", "atob"))
    .then((val) => pako.inflate(val, { to: "string" }))
    .then(logSize("inflate", "inflate"));

export const deflate = (value) =>
  Promise.resolve(value)
    .then(logSize("deflate", "initial"))
    .then((html) => pako.deflate(html, { to: "string" }))
    .then(logSize("deflate", "deflate"))
    .then(btoa)
    .then(logSize("deflate", "btoa"))
    .then(encodeURIComponent)
    .then(logSize("deflate", "encoded"));
