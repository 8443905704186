import { inflate } from "./compress";
const init = async () => {
  const url = new URL(location.href);
  Promise.resolve(url.searchParams.get("c"))
    .then((maybeContent) =>
      maybeContent
        ? maybeContent
        : Promise.reject(
            new Error(
              `Missing content :( \n\nCheck ${location.origin}/editor.html for details`
            )
          )
    )
    .then(inflate)
    .then((val) => document.write(val))
    .catch((err) => {
      const errorMessageDialog = document.createElement("div");
      errorMessageDialog.className = "error-message";
      errorMessageDialog.innerText =
        err.message || "Unexpected error has occured. Sorry :(";
      document.body.appendChild(errorMessageDialog);
    });
};
document.addEventListener("DOMContentLoaded", init);
